<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 mb-n4 primary--text"
        >Detalle de la tabla: {{ this.tablaNombre }}</v-card-title
      >
      <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
        <v-container class="pt-0">
          <v-row justify="end">
            <v-col col="9" class="pt-0 pb-0" align="start" align-self="center">
              <v-btn-toggle dense v-model="text" color="primary" group>
                <v-btn
                  value="CONVENIOS"
                  :disabled="mostrarTablaPracticas"
                  @click="changeTab(1)"
                  >Prácticas</v-btn
                >
                <v-btn
                  value="PARENTESCOS"
                  :disabled="mostrarTablaAranceles"
                  @click="changeTab(2)"
                >
                  Aranceles</v-btn
                >
                <v-btn
                  value="COBERTURA ESPECIAL"
                  :disabled="mostrarTablaPropietarios"
                  @click="changeTab(3)"
                  >Propietarios</v-btn
                >
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <div v-if="mostrarTablaPracticas">
        <v-card>
          <v-data-table
            :headers="headersPractica"
            :items="detallePractica"
            class="elevation-1"
            :search="search"
            :loading="isLoadingDetalle"
            :page.sync="pagination"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row v-if="mostrarTablaPracticas">
                  <v-col cols="8">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <!-- Tabla aranceles -->
      <div v-if="mostrarTablaAranceles">
        <v-card-title class="pl-1 mt-n5 primary--text"
          >Conjunto de aranceles: {{ this.conjuntoArancel }}</v-card-title
        >
        <v-card>
          <v-data-table
            :headers="headersAranceles"
            :items="arancelesTabla"
            class="elevation-1"
            :loading="isLoadingAranceles"
            :page.sync="pagination"
          >
            <template v-slot:[`item.valor`]="{ item }">
              <span>{{ addDecimals(item.valor) }}</span>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <!-- Tabla propietarios -->
      <div v-if="mostrarTablaPropietarios">
        <v-card>
          <v-data-table
            :headers="headersPropietarios"
            :items="propietariosTabla"
            :loading="isLoadingPropietarios"
            :search="searchPropietarios"
            class="elevation-1"
            item-key="identificador"
            :page.sync="pagination"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row v-if="mostrarTablaPropietarios">
                  <v-col cols="8">
                    <v-text-field
                      v-model="searchPropietarios"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" md="4" class="py-0 mt-3">
                    <v-menu
                      ref="vigencia-desde"
                      v-model="menuFechaVigencia"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVigenciaSelected"
                          label="Vigentes al..."
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          hint="Formato DD/MM/AAAA"
                          :rules="fechaVigenciaSelected ? rules.validDate : []"
                          @blur="
                            fechaVigencia = parseDateToIso(fechaVigenciaSelected)
                          "
                          outlined
                          @change="changeFechaVig();"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVigencia"
                        no-title
                        @change="fechaVigenciaSelected = formatDate(fechaVigencia); changeFechaVig();"
                        @input="menuFechaVigencia = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalDetalleProp(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Ver detalle</span>
            </v-tooltip>
          </template>
          </v-data-table>
        </v-card>
      </div>
      <v-row>
        <v-col cols="10" md="10" class="mt-3 py-1" v-if="!mostrarTablaPropietarios">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                v-on="on"
                v-bind="attrs"
                @click="exportTabla()"
              >
                Exportar tabla
              </v-btn>
            </template>
            <span>Exportar tabla en formato importación</span>
          </v-tooltip>
        </v-col >
        <v-col v-if="mostrarTablaPropietarios" cols="10" md="10" class="mt-3 py-1">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                v-on="on"
                v-bind="attrs"
                @click="exportTablaPropietarios()"
              >
                Exportar propietarios
              </v-btn>
            </template>
            <span>Exportar detalle de propietarios</span>
          </v-tooltip>
        </v-col >
        <v-col cols="2" md="2" class="mt-3 text-right py-1" align="end">
          <v-btn outlined @click="closeModal"> Cerrar </v-btn>
        </v-col>
      </v-row>
      <v-dialog
        v-if="openModalVerDetalle"
        v-model="openModalVerDetalle"
        max-width="60%"
        @keydown.esc="closeModalDetalle"
        persistent
      >
        <DetallePropietarioTablaFactu
          @closeModalDetalle="closeModalDetalle"
          :infoToDetallePropModal="infoToDetallePropModal"
        ></DetallePropietarioTablaFactu>
      </v-dialog>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import helpersExcel from "@/utils/helpers/importExportExcel";
import DetallePropietarioTablaFactu from "./DetallePropietarioTablaFactu.vue";
import { mask } from "vue-the-mask";
export default {
  name: "VerTablaConsulta",
  directives: { mask },
  components: {
    DetallePropietarioTablaFactu
  },
  props: {
    tablaId: {
      type: Number,
      required: true
    },
    tablaNombre: {
      type: String
    },
    ctaId: {
      type: Number
    }
  },
  data: () => ({
    isLoadingDetalle: false,
    rules: rules,
    calendarIcon: enums.icons.CALENDAR,
    searchIcon: enums.icons.SEARCH,
    editIcon: enums.icons.SEE,
    search: "",
    searchPropietarios: "",
    isLoadingPropietarios: false,
    isLoadingAranceles: false,
    mostrarTablaPracticas: true,
    mostrarTablaAranceles: false,
    mostrarTablaPropietarios: false,
    openModalVerDetalle: false,
    infoToDetallePropModal: null,
    text: "",
    menuFechaVigencia: false,
    fechaVigenciaSelected: null,
    fechaVigencia: null,
    detallePractica: [],
    headersPractica: [
      {
        text: "Nomenclador",
        value: "nomenclador",
        sortable: false
      },
      {
        text: "Código desde",
        value: "codigoDesde",
        sortable: false,
        align: "end"
      },
      {
        text: "Código hasta",
        value: "codigoHasta",
        sortable: false,
        align: "end"
      },
      {
        text: "Área",
        value: "area",
        sortable: false,
        align: "end"
      },
      {
        text: "Especialista",
        value: "especialidad",
        sortable: false,
        align: "end"
      },
      {
        text: "Anestesista",
        value: "anestesia",
        sortable: false
      },
      {
        text: "Ayudante",
        value: "ayudante",
        sortable: false,
        align: "end"
      },
      {
        text: "Gastos",
        value: "gastos",
        sortable: false,
        align: "end"
      }
    ],
    propietariosTablaToDetalle: [],
    propietariosTabla: [],
    headersPropietarios: [
      {
        text: "Agente de pago",
        value: "agenteNombre",
        sortable: false
      },
      {
        text: "Origen",
        value: "origen",
        sortable: false
      },
      {
        text: "Prestador",
        value: "prestadorNombre",
        sortable: false
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "end",
        width: "5%"
        }
    ],
    arancelesTabla: [],
    headersAranceles: [
      {
        text: "Unidad",
        value: "unidad",
        sortable: false
      },
      {
        text: "Valor",
        value: "valor",
        sortable: false,
        align: "end"
      }
    ],
    ctaFacturacionName: "",
    conjuntoArancel: "",
    allowedActions: null,
    pagination: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setDetalleProceso();
    this.setVerPropietario();
    this.setVerAranceles();
    this.setName();
  },
  methods: {
    ...mapActions({
      getTablaFacturacionDetalle: "prestadores/getTablaFacturacionDetalle",
      getPropietariosTablaByFecVig: "prestadores/getPropietariosTablaByFecVig",
      getTablaFacturacionAranceles: "prestadores/getTablaFacturacionAranceles",
      getNombreCtaFacturacion: "prestadores/getNombreCtaFacturacion",
      setAlert: "user/setAlert",
      exportTablaFacturacionImportacion:
        "prestadores/exportTablaFacturacionImportacion"
    }),
    async changeFechaVig(){
      if(this.fechaVigencia != null && this.fechaVigenciaSelected != null){
        this.fechaVigencia = this.parseDateToIso(this.fechaVigenciaSelected);
        this.isLoadingPropietarios = true;
        const data = {
          tablaId: this.tablaId,
          fechaVig: this.parseDateToIso(this.fechaVigenciaSelected),
          listado: "R"
        };
        const dataComplete = {
          tablaId: this.tablaId,
          fechaVig: this.parseDateToIso(this.fechaVigenciaSelected),
          listado: ""
        };
        try {
          const response = await this.getPropietariosTablaByFecVig(data);
          const responseComplete = await this.getPropietariosTablaByFecVig(dataComplete);
          this.propietariosTabla = response;
          this.propietariosTablaToDetalle = responseComplete;
          this.isLoadingPropietarios = false;
        } catch (error) {
          this.setAlert({ type: "warning", message: "La fecha no es valida." });
          this.isLoadingPropietarios = false;
        }
        this.isLoadingPropietarios = false;
      }else if(this.fechaVigenciaSelected == null){
        this.isLoadingPropietarios = true;
        const data = {
          tablaId: this.tablaId,
          fechaVig: null,
          listado: "R"
        };
        const dataComplete = {
          tablaId: this.tablaId,
          fechaVig: null,
          listado: ""
        };
        try {
          const response = await this.getPropietariosTablaByFecVig(data);
          const responseComplete = await this.getPropietariosTablaByFecVig(dataComplete);
          this.propietariosTabla = response;
          this.propietariosTablaToDetalle = responseComplete;
          this.isLoadingPropietarios = false;
        } catch (error) {
          this.setAlert({ type: "warning", message: "La fecha no es valida." });
          this.isLoadingPropietarios = false;
        }
        this.isLoadingPropietarios = false;
      }else if(this.fechaVigenciaSelected != null && this.fechaVigencia == null){
        this.isLoadingPropietarios = true;
        const data = {
          tablaId: this.tablaId,
          fechaVig: this.parseDateToIso(this.fechaVigenciaSelected),
          listado: "R"
        };
        const dataComplete = {
          tablaId: this.tablaId,
          fechaVig: this.parseDateToIso(this.fechaVigenciaSelected),
          listado: ""
        };
        try {
          const response = await this.getPropietariosTablaByFecVig(data);
          const responseComplete = await this.getPropietariosTablaByFecVig(dataComplete);
          this.propietariosTabla = response;
          this.propietariosTablaToDetalle = responseComplete;
          this.isLoadingPropietarios = false;
        } catch (error) {
          this.setAlert({ type: "warning", message: "La fecha no es valida." });
          this.isLoadingPropietarios = false;
        }
        this.isLoadingPropietarios = false;
      }
    },
    async setName() {
      const data = await this.getNombreCtaFacturacion(this.tablaId);
      this.ctaFacturacionName = data;
    },
    async setVerPropietario() {
      this.isLoadingPropietarios = true;
      const data = {
        tablaId: this.tablaId,
        fechaVig: null,
        listado: "R"
      };
      const dataComplete = {
        tablaId: this.tablaId,
        fechaVig: null,
        listado: ""
      };
      const response = await this.getPropietariosTablaByFecVig(data);
      const responseComplete = await this.getPropietariosTablaByFecVig(dataComplete);
      this.propietariosTablaToDetalle = responseComplete;
      this.propietariosTabla = response;
      this.isLoadingPropietarios = false;
    },
    async setVerAranceles() {
      this.isLoadingAranceles = true;
      const data = await this.getTablaFacturacionAranceles(this.tablaId);
      this.conjuntoArancel = data.nombre;
      this.arancelesTabla = data.lista;
      this.isLoadingAranceles = false;
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    async setDetalleProceso() {
      this.isLoadingDetalle = true;
      const data = await this.getTablaFacturacionDetalle(this.ctaId);
      this.detallePractica = data;
      this.isLoadingDetalle = false;
    },
    async exportTabla() {
      const data = await this.exportTablaFacturacionImportacion(this.tablaId);
      this.exportarTabla = data;
      this.exportTablaImport()
    },
    exportTablaImport() {
      let result = [];
      this.exportarTabla?.forEach(x =>
        result.push({
          ["Nomenclador"]: x.nomenclador,
          ["Codigo desde"]: x.codigoDesde,
          ["Codigo hasta"]: x.codigoHasta,
          ["Area"]: x.area,
          ["Importe especialista"]: x.ctaEspecialista,
          ["Importe ayudante"]: x.ctaAyudante,
          ["Importe anestesista"]: x.ctaAnestesista,
          ["Importe gastos"]: x.ctaGastos,
          ["Unidad especialista"]: x.uniEspecialista,
          ["Unidad ayudante"]: x.uniAyudante,
          ["Unidad anestesista"]: x.uniAnestesista,
          ["Unidad gastos"]: x.uniGastos,
          
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Formato importación");
    },
    exportTablaPropietarios(){
      let result = [];
      this.propietariosTablaToDetalle?.forEach(x =>
        result.push({
          ["Agente de pago"]: x.agenteNombre,
          ["Origen"]: x.origen,
          ["Prestador"]: x.prestadorNombre,
          ["Plan"]: x.planNombre,
          ["Obra social"]: x.obraSocial,
          ["Vigencia"]: x.fechaVigencia,
          ["Número de proceso"]: x.procAsignTablaFacId,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Detalle de propietarios");
    },
    closeModal() {
      this.$emit("closeModalVerTabla");
    },
    changeTab(num) {
      switch (num) {
        case 1:
          this.mostrarTablaPracticas = true;
          this.mostrarTablaAranceles = false;
          this.mostrarTablaPropietarios = false;
          break;
        case 2:
          this.mostrarTablaPracticas = false;
          this.mostrarTablaAranceles = true;
          this.mostrarTablaPropietarios = false;
          break;
        case 3:
          this.mostrarTablaPracticas = false;
          this.mostrarTablaAranceles = false;
          this.mostrarTablaPropietarios = true;
          break;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    openModalDetalleProp(item){
      this.openModalVerDetalle = true;
      this.infoToDetallePropModal = {
        AgenteId: item.agenteId,
        PreId: item.preId,
        OriId: item.oriId,
        TablaId: item.tablaId,
        AgenteNom: item.agenteNombre,
        OriNom: item.origen,
        PrestaNom: item.prestadorNombre
      }
    },
    closeModalDetalle() {
      this.openModalVerDetalle = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}
</style>
