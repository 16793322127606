<template>
  <v-card>
    <v-container>
      <div>
        <v-card>
          <v-row class="mb-n8">
            <v-col cols="12" class="pt-0">
              <v-row>
                <v-col cols="4" md="4" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Agente de pago"
                    v-model="AgentePago"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Origen"
                    v-model="Origen"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    label="Prestador"
                    v-model="Prestador"
                    autocomplete="off"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headersPropietario"
            :items="detallePropietario"
            class="elevation-1"
            dense
            :search="search"
            :loading="isLoadingDetalle"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="7" class="mt-n2">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <v-row>
        <v-col cols="10" md="10" class="mt-3 py-1">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                v-on="on"
                v-bind="attrs"
                @click="exportDetallePropietario()"
              >
                Exportar propietario
              </v-btn>
            </template>
            <span>Exportar detalle de propietario</span>
          </v-tooltip>
        </v-col>
        <v-col cols="2" md="2" class="mt-3 text-right py-1" align="end">
          <v-btn outlined @click="closeModalDetalle"> Cerrar </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import helpersExcel from "@/utils/helpers/importExportExcel";
export default {
  name: "DetallePropietarioTablaFactu",
  props: {
    infoToDetallePropModal: {
      type: Object,
    },
  },
  data: () => ({
    searchIcon: enums.icons.SEARCH,
    search: "",
    AgentePago: null,
    Origen: null,
    Prestador: null,
    detallePropietario: [],
    headersPropietario: [
      {
        text: "Obra social",
        value: "obraSocial",
        sortable: false,
      },
      {
        text: "Plan",
        value: "planNombre",
        sortable: false,
      },
      {
        text: "Vigencia",
        value: "fechaVigencia",
        sortable: false,
      },
      {
        text: "Proceso de asignación",
        value: "procAsignTablaFacId",
        align: "end",
        sortable: false,
      },
    ],
    isLoadingDetalle: false,
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setDetallePropietario();
  },
  methods: {
    ...mapActions({
      getDetallePropietarioTabla: "prestadores/getDetallePropietarioTabla",
    }),
    async setDetallePropietario() {
      this.isLoadingDetalle = true;
      const data = {
        TablaId: this.infoToDetallePropModal.TablaId,
        AgeId: this.infoToDetallePropModal.AgenteId,
        OriId: parseInt(this.infoToDetallePropModal.OriId),
        PreId: parseInt(this.infoToDetallePropModal.PreId),
      };
      const response = await this.getDetallePropietarioTabla(data);
      this.detallePropietario = response;
      this.AgentePago = this.infoToDetallePropModal.AgenteNom;
      this.Origen = this.infoToDetallePropModal.OriNom;
      this.Prestador = this.infoToDetallePropModal.PrestaNom;
      this.isLoadingDetalle = false;
    },
    exportDetallePropietario() {
      let result = [];
      this.detallePropietario?.forEach((x) =>
        result.push({
          ["Agente de pago"]: x.agenteNombre,
          ["Origen"]: x.origen,
          ["Prestador"]: x.prestadorNombre,
          ["Obra social"]: x.obraSocial,
          ["Plan"]: x.planNombre,
          ["Vigencia"]: x.fechaVigencia,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Detalle de propietario");
    },
    closeModalDetalle() {
      this.$emit("closeModalDetalle");
    },
  },
};
</script>